<template>
<!--招聘信息抓取-->
  <div class="recruitInfoCapture">
    <div class="header">
      <h2 style="margin: 0">选择抓取网站</h2>
      <div style="width: 100%;display: flex;justify-content: center">
        <a-select  style="width: 35%" placeholder="请选择抓取网站" :allowClear="true" @change="handleChange">
          <a-select-option value="jack">
            Jack
          </a-select-option>
        </a-select>
        <a-button style="border-radius: 0" type="primary">抓取</a-button>
      </div>
    </div>
    <div class="table">
      <a-button style="margin-right: 2%" type="primary">全部选择</a-button>
      <a-button  >全部删除</a-button>

      <a-table :columns="columns" :data-source="InfoTable" :pagination="false" rowKey="id"
      >
        <span slot="operate" slot-scope="text,item">
          <a style="margin-left: 10px"> 选择 </a>

          <a-popconfirm
              cancel-text="否"
              ok-text="是"
              title="确定删除?"
              @cancel="delCancel"
              @confirm="delTable(item.id)"
          >
            <a>删除</a>
            </a-popconfirm>
        </span>
      </a-table>
      <div style="display: flex;justify-content: flex-end;right: 62px;position: fixed;bottom: 3.5rem;z-index: 3">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="pageChange"/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "recruitInfoCapture",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin/>,
      searchValue:'',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '医院名称',
          dataIndex: 'title',
        },
        {
          title: '职位',
          dataIndex: 'identity_no',
        },
        {
          title: '所在地',
          dataIndex: 'created_time',
        },
        {
          title: '薪资要求',
          dataIndex: 'release_time',
        },
        {
          title: '待遇',
          dataIndex: 'state',
        },
        {
          title: '来源',
          dataIndex: 'form',
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operate'},
        },],
      InfoTable: [],
    }
  },
  methods:{
    handleChange(value){
      console.log(`selected ${value}`);
    },
    //分页跳转
    pageChange() {
    },
    //删除
    delTable() {
    },
    delCancel() {
    },
  }
}
</script>

<style lang="scss" scoped>
.recruitInfoCapture{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .header{
    height: 100px;
    background: white;
    padding: 20px;
    ::v-deep .ant-select-selection{
      border-radius: 0!important;
    }
  }
  .table{
    margin-top: 10px;
    background: white;
    padding: 20px;
  }
}
</style>
